import { Button, Col, Row } from 'antd';
import Title from 'antd/es/typography/Title';

function CompanyDetails() {
    return (
        <>
            <Row justify={'center'}>
                <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 10 }} lg={{ span: 10 }} >
                    <Title level={3}>
                        South Star Group
                    </Title>
                    <p >
                        Let us real your dream..
                    </p>
                    <div className='default-text'>
                        <p>This project turned out to be a very successful one and had helped foster the growth of trust and confidence of the urban people in "MARYLAND CITY" Kuakata burgeoning population, coupled with the conspicuously slow growth of housing led to the landmark success of South Star Group.</p>
                        <p>Driven by the ramifications of this success, South Star Group geared up to invest in new fields, including manufacturing and trading. More enterprises were established in the early, covering diverse activities involving the production of Agro Farm, Foods & Beverage, distribution, and a trading </p>
                    </div>

                    {/* <Button type='primary'>Read More</Button> */}

                </Col>
                <Col xs={{ span: 22 }} sm={{ span: 22 }} md={{ span: 10, offset: 2 }} lg={{ span: 10, offset: 2 }}>
                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/tGI2IaMTkyA?si=BZYiz1QShi_cIAyJ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                </Col>
            </Row>
            <br />
        </>
    )
}

export default CompanyDetails;