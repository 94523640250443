import { Col, Image, Row, Skeleton, Typography } from "antd";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { API_URL } from "../../settings";
import HtmlContent from "../HtmlContent";

const { Title } = Typography;

function DirectorMessage() {
  const [messageData, setMessageData] = useState({
    id: null,
    messengerName: "",
    messengerPhotoUrl: "",
    messengerDesignation: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const fetchMessageData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${API_URL}/company-messages-by-type/DIRECTOR_MESSAGE`);
      setMessageData({
        id: data.id,
        messengerName: data.messenger_name,
        messengerPhotoUrl: data.messenger_photo_name
          ? `${API_URL}/image-download/${data.messenger_photo_name}`
          : "",
        messengerDesignation: data.messenger_designation,
        message: data.message,
      });
    } catch (error) {
      console.error("Server error:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMessageData();
  }, [fetchMessageData]);

  return (
    <div>
      <Row justify="center">
        <Col span={24}>
          <Title style={{ textAlign: "center" }}>Director's Message</Title>
        </Col>
      </Row>
      <Row justify="center" align="top">
        <Col
          className="default-text"
          style={{ textAlign: "center" }}
          xs={22}
          sm={22}
          md={6}
          lg={6}
        >
          <Skeleton active avatar loading={loading}>
            {messageData.messengerPhotoUrl && <Image src={messageData.messengerPhotoUrl} />}
            {messageData.messengerName && (
              <Title level={4}>{messageData.messengerName}</Title>
            )}
            {messageData.messengerDesignation && (
              <Title level={5}>{messageData.messengerDesignation}</Title>
            )}
          </Skeleton>
        </Col>
        <Col
          xs={22}
          sm={22}
          md={12}
          lg={12}
          style={{ paddingLeft: 16 }}
        >
          <Skeleton active loading={loading}>
            <div className="default-text">
              <HtmlContent html={messageData.message || ""} />
            </div>
          </Skeleton>
        </Col>
      </Row>
    </div>
  );
}

export default DirectorMessage;
