import { Col, Row, Skeleton } from "antd";
import Title from "antd/es/typography/Title";
import axios from "axios";
import { useEffect, useState, useCallback } from "react";
import { API_URL } from "../../settings";
import HtmlContent from "../HtmlContent";

function CorporateProfile() {
  const [profileData, setProfileData] = useState({
    id: null,
    content: "",
    title: "",
  });
  const [loading, setLoading] = useState(false);

  const fetchCorporateProfile = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_URL}/about-us-by-type/CORPORATE_PROFILE`);
      const { id, description, title } = response.data;
      setProfileData({ id, content: description, title });
    } catch (error) {
      console.error("Server error", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCorporateProfile();
  }, [fetchCorporateProfile]);

  return (
    <Row justify="center">
      <Col span={20}>
        <Title>{profileData.title || "Corporate Profile"}</Title>
        <Skeleton active loading={loading}>
          <div className="default-text">
            <HtmlContent html={profileData.content || ""} />
          </div>
        </Skeleton>
      </Col>
    </Row>
  );
}

export default CorporateProfile;
