import React from 'react'
import SliderComponent from '../Slider/SliderComponent';
import HeadingNews from '../HeadingNews/HeadingNews';
import CompanyDetails from '../CompanyDetails/CompanyDetails';
import ICompany from '../../interfaces/ICompany';
interface HeaderComponentProps {
    company: ICompany | undefined;
  }
function HomePage({ company }: HeaderComponentProps) {
    return (
        <>
            <div className='mt-1'></div>
            <SliderComponent company={company}></SliderComponent>
            <HeadingNews company={company}></HeadingNews>
            <CompanyDetails></CompanyDetails>
        </>
    )
}

export default HomePage;