import React from 'react'

function Contact() {
  return (
    <div>
        <div>Contact</div>
        <div>Contact</div>
        <div>Contact</div>
        <div>Contact</div>
        <div>Contact</div>
    </div>
  )
}

export default Contact;