import DOMPurify from 'dompurify';
import React from 'react';
interface HtmlContentProps {
  html: string;
}

const HtmlContent: React.FC<HtmlContentProps> = ({ html }) => {
  // Sanitize the HTML before setting inner HTML
  // const safeHtml = DOMPurify.sanitize(html);
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default HtmlContent;
