import { Col, Row, Skeleton, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../../settings";
import HtmlContent from "../HtmlContent";

const { Title } = Typography;

function MissionVisionValues() {
  const [profileData, setProfileData] = useState({
    id: null,
    content: "",
    title: "Mission, Vision, and Values",
  });
  const [loading, setLoading] = useState(false);

  const fetchProfileData = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await axios.get(`${API_URL}/about-us-by-type/MISSION_VISION_VALUE`);
      setProfileData({
        id: data.id,
        content: data.description,
        title: data.title || "Mission, Vision, and Values",
      });
    } catch (error) {
      console.error("Server error:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  return (
    <Row justify="center">
      <Col span={20}>
      <Title>{profileData.title || "Mission, Vision, and Values"}</Title>
        <Skeleton active loading={loading}>
          <div className="default-text">
            <HtmlContent html={profileData.content || ""} />
          </div>
        </Skeleton>
      </Col>
    </Row>
  );
}

export default MissionVisionValues;
