import { Button, Card, Col, Flex, List, Row, Skeleton } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import IGallery from "../../interfaces/IGallery";
import { API_URL } from "../../settings";
import Title from "antd/es/typography/Title";

function Galleries() {
  const [initItemLoading, setInitItemLoading] = useState(true);
  const [galleryLoading, setItemLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);

  const [cardGalleries, setCardGalleries] = useState<IGallery[]>([]);

  const onLoadMore = () => {
    setCurrentPage(currentPage + 1);
    setItemLoading(true);
    axios
      .get(
        `${API_URL}/public/galleries/published?page=${currentPage}&per_page=12`
      )
      .then((response) => {
        response.data.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
          x["loading"] = true;
        });
        const newData = cardGalleries.concat(response.data.data);
        setCardGalleries(newData);
        setItemLoading(false);
        setIsLastPage(response.data.next_page_url ? false : true);
        window.dispatchEvent(new Event("resize"));
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setItemLoading(false);
      });
  };

  const loadMore =
    !isLastPage && !initItemLoading && !galleryLoading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button onClick={onLoadMore}>Load more</Button>
      </div>
    ) : null;

  const getItemList = () => {
    setItemLoading(true);
    axios
      .get(
        `${API_URL}/public/galleries/published?page=${currentPage}&per_page=12`
      )
      .then((response) => {
        response.data.data.map((x: { [x: string]: any; id: any }) => {
          x["key"] = x.id;
        });
        setCardGalleries(response.data.data);
        setInitItemLoading(false);
        setInitItemLoading(false);
        setCurrentPage(currentPage + 1);
        setIsLastPage(response.data.next_page_url ? false : true);
        setItemLoading(false);
      })
      .catch((err) => {
        // Handle error
        console.log("server error");
        setItemLoading(false);
      });
  };

  useEffect(() => {
    getItemList();

    return () => {};
  }, []);

  return (
    <>
      <Row justify={"center"}>
          <Title level={4}>Galleries</Title>
      </Row>
      <Row justify={"center"}>
        <Col span={20}>
          <List
            loadMore={loadMore}
            loading={initItemLoading}
            grid={{
              gutter: 20,
              xs: 1,
              sm: 2,
              md: 4,
              lg: 4,
              xl: 4,
              xxl: 4,
            }}
            dataSource={cardGalleries}
            renderItem={(gallery) => (
              <List.Item className="mb-4">
                <Skeleton avatar title={false} loading={galleryLoading} active>
                  <Link
                    className="text-decoration-none"
                    to={`/galleries/${gallery.id}`}
                  >
                    <Card
                      size="small"
                      bordered={true}
                      cover={
                        <>
                          <img
                            style={{ height: "217px" }}
                            alt={gallery.thumbnail_name + "image"}
                            src={
                              API_URL +
                              "/image-download/" +
                              gallery.thumbnail_name
                            }
                          />
                        </>
                      }
                    >
                      <div>
                        <Title level={5}>{gallery.title}</Title>
                      </div>
                    </Card>
                  </Link>
                </Skeleton>
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </>
  );
}

export default Galleries;
